#motd {
  background: #FFFA99;
  border-bottom: 1px #807900 solid;
  font-size: 110%;
  padding: 5px;
  padding-left: 56px;
}
#motd-close {
  display: inline-block;
  vertical-align: middle;
}
#motd-message {
  vertical-align: middle;
}
#motd-message a {
  color: blue;
  font-weight: bold;
  text-decoration: none;
}
/*# sourceMappingURL=motd.css.map */